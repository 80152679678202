<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 数据统计
        </el-breadcrumb-item>
        <el-breadcrumb-item>数据完整率</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-input
            clearable
            size="mini"
            v-model="companyNameOrMonitoryPointNameOrMnInput"
            placeholder="企业名称 / 监控点名称 / MN号"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
          <el-button
            @click="handleExport"
            plain
            type="warning"
            icon="el-icon-download"
            size="mini"
          >
            导出
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row :gutter="5">
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">选择日期 </span>
          <el-date-picker
            @change="handleDateChange"
            v-model="date"
            type="date"
            size="mini"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="block" style="margin-right: 1%">
          <span class="demonstration">选择区域 </span>
          <el-select
            @change="handleAreaChange"
            size="mini"
            v-model="select.areaName"
            placeholder="全部区域"
          >
            <el-option
              v-for="item in options.areaOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="block">
          <span class="demonstration">选择监控点类型 </span>
          <el-select
            @change="handleMonitoryPointTypeChange"
            size="mini"
            v-model="select.monitoryPointType"
            placeholder="全部类型"
          >
            <el-option
              v-for="item in options.monitoryPointTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column type="index" :index="1" label="序号">
        </el-table-column>
        <el-table-column prop="companyName" label="企业名称"> </el-table-column>
        <el-table-column prop="monitoryPointName" label="监控点名称">
        </el-table-column>
        <el-table-column prop="mn" label="MN号"> </el-table-column>
        <el-table-column label="实时数据">
          <el-table-column prop="realData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="realData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column
            prop="realData.integrityRate"
            label="完整率"
            sortable
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="分钟数据">
          <el-table-column prop="minuteData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="minuteData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column
            prop="minuteData.integrityRate"
            label="完整率"
            sortable
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="小时数据">
          <el-table-column prop="hourData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="hourData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column
            prop="hourData.integrityRate"
            label="完整率"
            sortable
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="日数据">
          <el-table-column prop="dayData.currentCount" label="实收">
          </el-table-column>
          <el-table-column prop="dayData.theoryCount" label="应收">
          </el-table-column>
          <el-table-column prop="dayData.integrityRate" label="完整率" sortable>
          </el-table-column>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template #default="scope">
            <div>
              <el-button
                size="mini"
                type="text"
                @click="handleOpenMonthStatistic(scope.$index, scope.row)"
              >
                查看月统计
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import router from "@/router";
import { getAreaOptions } from "@/api/area";
import { getMonitoryPointTypeOptions } from "@/api/monitory-point-type";
import { getTable } from "@/api/statistic";
import { exportStatistic } from "@/api/export";

export default {
  name: "Statistic",
  data() {
    return {
      multipleSelection: [],
      companyNameOrMonitoryPointNameOrMnInput: "",
      date: new Date(),
      options: {
        areaOptions: [],
        monitoryPointTypeOptions: [],
      },
      select: {
        areaName: "",
        monitoryPointType: "",
      },
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getOptions();
    this.getList();
  },
  methods: {
    handleClear() {
      this.companyNameOrMonitoryPointNameOrMnInput = "";
    },
    handleSearch() {
      console.log(this.companyNameOrMonitoryPointNameOrMnInput);
      this.getList();
    },
    handleExport() {
      // 获取MN
      const mns = this.$_.map(this.multipleSelection, "mn");
      let requestBody;
      if (mns.length === 0) {
        requestBody = {
          dateTime: new Date(this.date).getTime(),
        };
      } else {
        requestBody = {
          dateTime: new Date(this.date).getTime(),
          mns: mns,
        };
      }
      exportStatistic(requestBody);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleOpenMonthStatistic(index, row) {
      console.log(index, row);
      const mn = row.mn;
      const monitoryPointName = row.monitoryPointName;
      router.push({
        path: "statistic-month",
        query: { mn: mn, monitoryPointName: monitoryPointName },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    handleDateChange(val) {
      this.date = val;
      this.getList();
    },
    handleAreaChange(val) {
      this.select.areaName = val;
      this.getList();
    },
    handleMonitoryPointTypeChange(val) {
      this.select.monitoryPointType = val;
      this.getList();
    },
    // 获取区域名称
    // 获取监控点类型
    getOptions() {
      getAreaOptions().then((result) => {
        this.options.areaOptions = result.data;
        this.options.areaOptions.unshift({ value: "", label: "全部" });
      });
      getMonitoryPointTypeOptions().then((result) => {
        this.options.monitoryPointTypeOptions = result.data;
        this.options.monitoryPointTypeOptions.unshift({
          value: "",
          label: "全部",
        });
      });
    },
    getList() {
      const param = {
        companyNameOrMonitoryPointNameOrMnInput:
          this.companyNameOrMonitoryPointNameOrMnInput,
        areaName: this.select.areaName,
        monitoryPointType: this.select.monitoryPointType,
        date: new Date(this.date).getTime(),
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
