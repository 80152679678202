import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/statistic",
    method: "Get",
    params: params,
  });
}

// 获取单个监控点统计
export function getMonthTable(params) {
  return request({
    url: "/statistic/monitory-point",
    method: "Get",
    params: params,
  });
}
